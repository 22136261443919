import React, { useState } from 'react';

const workstreams = [
  { name: 'Team', color: '#ff9a9e' },
  { name: 'Clone Cluster Build', color: '#a18cd1' },
  { name: 'Pilot Customers', color: '#84fab0' },
  { name: 'Middleware Build', color: '#fad0c4' },
  { name: 'Token (Strategy, WP, Community, App)', color: '#fbc2eb' },
  { name: 'Legal', color: '#8fd3f4' },
  { name: 'Fundraise', color: '#ff9a9e' },
  { name: 'Customer App', color: '#a18cd1' },
];

const startDate = new Date('2024-07-01');
const endDate = new Date('2024-12-31');
const totalDays = Math.ceil((endDate - startDate) / (1000 * 60 * 60 * 24));
const weeks = Math.ceil(totalDays / 7);

const CalendarIcon = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round">
    <rect x="3" y="4" width="18" height="18" rx="2" ry="2"></rect>
    <line x1="16" y1="2" x2="16" y2="6"></line>
    <line x1="8" y1="2" x2="8" y2="6"></line>
    <line x1="3" y1="10" x2="21" y2="10"></line>
  </svg>
);

const DateModal = ({ isOpen, onClose, onSave, initialStart, initialEnd }) => {
  const [start, setStart] = useState(initialStart || '2024-07-01');
  const [end, setEnd] = useState(initialEnd || '2024-07-31');

  if (!isOpen) return null;

  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center">
      <div className="bg-white p-4 rounded-lg">
        <h3 className="text-lg font-bold mb-4">Set Date Range</h3>
        <div className="mb-4">
          <label className="block mb-2">Start Date:</label>
          <input 
            type="date" 
            value={start} 
            onChange={(e) => setStart(e.target.value)} 
            min="2024-07-01" 
            max="2024-12-31"
            className="border rounded p-2"
          />
        </div>
        <div className="mb-4">
          <label className="block mb-2">End Date:</label>
          <input 
            type="date" 
            value={end} 
            onChange={(e) => setEnd(e.target.value)} 
            min="2024-07-01" 
            max="2024-12-31"
            className="border rounded p-2"
          />
        </div>
        <div className="flex justify-end">
          <button onClick={onClose} className="mr-2 px-4 py-2 bg-gray-200 rounded">Cancel</button>
          <button onClick={() => onSave(start, end)} className="px-4 py-2 bg-blue-500 text-white rounded">Save</button>
        </div>
      </div>
    </div>
  );
};

const InteractiveRoadmap = () => {
  const [barDates, setBarDates] = useState(workstreams.map(() => ({ start: '2024-07-01', end: '2024-07-31' })));
  const [modalOpen, setModalOpen] = useState(false);
  const [currentBar, setCurrentBar] = useState(null);

  const openModal = (index) => {
    setCurrentBar(index);
    setModalOpen(true);
  };

  const saveDates = (start, end) => {
    setBarDates(prev => {
      const newDates = [...prev];
      newDates[currentBar] = { start, end };
      return newDates;
    });
    setModalOpen(false);
  };

  const getBarStyle = (start, end) => {
    const startDays = Math.ceil((new Date(start) - startDate) / (1000 * 60 * 60 * 24));
    const endDays = Math.ceil((new Date(end) - startDate) / (1000 * 60 * 60 * 24));
    const width = Math.max(((endDays - startDays) / totalDays) * 100, 20); // Ensure minimum width of 20%
    const marginLeft = (startDays / totalDays) * 100;
    return { width: `${width}%`, marginLeft: `${marginLeft}%` };
  };

  const formatDate = (date) => {
    const d = new Date(date);
    return `${d.getMonth() + 1}/${d.getDate()}`;
  };

  return (
    <div className="p-4 bg-gray-100 rounded-lg">
      <h2 className="text-2xl font-bold mb-4 text-center">Swarm Workstreams</h2>
      <div className="mb-2 flex justify-between relative">
        {[...Array(weeks)].map((_, i) => (
          <div key={i} className="flex flex-col items-center">
            {i % 2 === 0 && (
              <span className="text-xs text-gray-500 mb-1">
                {formatDate(new Date(startDate.getTime() + i * 7 * 24 * 60 * 60 * 1000))}
              </span>
            )}
            <div className={`border-l border-gray-300 ${i % 2 === 0 ? 'h-3' : 'h-2'}`}></div>
          </div>
        ))}
      </div>
      {workstreams.map((stream, index) => (
        <div key={stream.name} className="mb-2 h-12 bg-white rounded-full overflow-hidden relative">
          <div 
            className="h-full rounded-full flex items-center px-4 font-semibold absolute left-0 top-0"
            style={{
              ...getBarStyle(barDates[index].start, barDates[index].end),
              backgroundColor: stream.color,
              transition: 'width 0.3s ease-out, margin-left 0.3s ease-out'
            }}
          >
            <span className="text-gray-700 whitespace-nowrap">{stream.name}</span>
          </div>
          <button 
            className="absolute right-2 top-1/2 transform -translate-y-1/2 bg-white rounded-full p-1 shadow"
            onClick={() => openModal(index)}
          >
            <CalendarIcon />
          </button>
        </div>
      ))}
      <DateModal 
        isOpen={modalOpen}
        onClose={() => setModalOpen(false)}
        onSave={saveDates}
        initialStart={currentBar !== null ? barDates[currentBar].start : null}
        initialEnd={currentBar !== null ? barDates[currentBar].end : null}
      />
    </div>
  );
};

export default InteractiveRoadmap;