import React from 'react';
import { BrowserRouter as Router, Route, Link, Routes } from 'react-router-dom';
import InteractiveRoadmap from './InteractiveRoadmap';
import ColorfulEditableStrategyTable from './ColorfulEditableStrategyTable';
import SwarmPitch from './SwarmPitch';

function App() {
  return (
    <Router>
      <div className="App">
        <style>
          {`
            @import url('https://fonts.googleapis.com/css2?family=Play:wght@400;700&display=swap');
            body { font-family: 'Play', sans-serif; }
          `}
        </style>
        
        <nav className="bg-gray-800 p-4">
          <ul className="flex justify-center space-x-6">
            <li>
              <Link to="/" className="text-white hover:text-gray-300 font-semibold">Roadmap</Link>
            </li>
            <li>
              <Link to="/strategy" className="text-white hover:text-gray-300 font-semibold">Strategy</Link>
            </li>
            <li>
              <Link to="/pitch" className="text-white hover:text-gray-300 font-semibold">Investors</Link>
            </li>
          </ul>
        </nav>

        <div className="p-4">
          <Routes>
            <Route path="/" element={<InteractiveRoadmap />} />
            <Route path="/strategy" element={<ColorfulEditableStrategyTable />} />
            <Route path="/pitch" element={<SwarmPitch />} />
          </Routes>
        </div>
      </div>
    </Router>
  );
}

export default App;