import React from 'react';

const Slide = ({ children, className }) => (
  <div className={`bg-white p-8 rounded-lg shadow-xl flex flex-col justify-center items-center h-[600px] ${className}`}>
    {children}
  </div>
);

const SwarmPitch = () => {
  return (
    <div className="max-w-4xl mx-auto p-4 space-y-8 font-play">
      <style>
        {`
          @import url('https://fonts.googleapis.com/css2?family=Play:wght@400;700&display=swap');
          body { font-family: 'Play', sans-serif; }
        `}
      </style>
      
      <Slide className="bg-gradient-to-r from-blue-500 to-purple-600 text-white">
        <h1 className="text-4xl sm:text-5xl font-bold mb-4 text-center">SWARM: Democratizing GPU Computing</h1>
        <p className="text-2xl sm:text-3xl text-center">A Vision of the Future</p>
      </Slide>
      
      <Slide>
        <p className="text-xl sm:text-2xl text-gray-700 leading-relaxed text-center">
          <p><b>We have a vision of the future.</b></p><br></br>
          Let's first discuss if we are aligned on what we see as <b>inevitable</b>,<br></br> i.e. someone will build this future. <br></br> <br></br>
          Once we are aligned on that vision, only then does it make sense to discuss if SWARM can build that future.
        </p>
      </Slide>
      
      <Slide>
        <h2 className="text-3xl sm:text-4xl text-gray-800 font-semibold mb-6 text-center">We see a future where</h2>
        <p className="text-2xl sm:text-3xl text-gray-700 text-center">There will not just be 5 major AI models, but millions of specialized ones.</p>
        <img src="/droid.webp" alt="AI Models Visualization" className="mt-8 rounded-lg shadow-md w-3/4 max-h-64 object-contain" />
      </Slide>
      
      <Slide>
        <p className="text-2xl sm:text-3xl text-gray-700 leading-relaxed text-center">
          In order to enable that future we will need to democratize AI compute infrastructure.
        </p>
      </Slide>
      
      <Slide>
        <h2 className="text-3xl sm:text-4xl text-gray-800 font-semibold mb-6 text-center">Two Main Components:</h2>
        <ul className="list-disc text-2xl sm:text-3xl text-gray-700 space-y-4">
          <li>Price</li>
          <li>Complexity</li>
        </ul>
        <p className="mt-6 text-xl sm:text-2xl text-gray-700 text-center">
          We have all heard about price and lack of availability, but the complexity situation is even worse putting the training and inference of models out of the reach of most.
        </p>
      </Slide>
      
      <Slide className="bg-blue-50 border-l-8 border-blue-500">
        <h2 className="text-3xl sm:text-4xl text-blue-800 font-bold mb-6 text-center">SWARM is sovling this problem with a GPU grid.</h2>
        <p className="text-2xl sm:text-3xl text-blue-700 text-center">
          A protocol to abstract away the complexity and drastically reduce the cost of GPU Compute.
        </p>
      </Slide>
    </div>
  );
};

export default SwarmPitch;