import React, { useState, useEffect } from 'react';
import initialData from './strategyData.json';

const ColorfulEditableStrategyTable = () => {
  const [tableData, setTableData] = useState(initialData);

  useEffect(() => {
    const savedData = localStorage.getItem('strategyTableData');
    if (savedData) {
      setTableData(JSON.parse(savedData));
    } else {
      setTableData(initialData);
    }
  }, []);

  const handleInputChange = (sectionIndex, field, value) => {
    const updatedData = [...tableData];
    updatedData[sectionIndex][field] = value;
    setTableData(updatedData);
    localStorage.setItem('strategyTableData', JSON.stringify(updatedData));
  };

  return (
    <div className="p-4 bg-gray-100 rounded-lg overflow-x-auto">
      <style jsx>{`
        @media (max-width: 640px) {
          .hide-on-mobile {
            display: none;
          }
          .full-width-on-mobile {
            width: 100% !important;
          }
        }
      `}</style>
      <table className="w-full border-collapse border-2 border-gray-300 bg-white table-fixed">
        <colgroup>
          <col className="hide-on-mobile" style={{ width: '2.5%' }} />
          {tableData.map((section) => (
            <col key={section.section} className="full-width-on-mobile" style={{ width: `${97.5 / tableData.length}%` }} />
          ))}
        </colgroup>
        <thead>
          <tr>
            <th className="hide-on-mobile border-2 border-gray-300 p-2 bg-gray-200">#</th>
            {tableData.map((section) => (
              <th key={section.section} className="border-2 border-gray-300 p-2 text-black font-bold" style={{ backgroundColor: section.color }}>
                {section.section}
              </th>
            ))}
          </tr>
        </thead>
        <tbody>
          {[1, 2, 3, 4, 5].map((row) => (
            <React.Fragment key={row}>
              <tr>
                <td className="hide-on-mobile border-2 border-gray-300 p-2 font-bold bg-gray-200 text-center">{row}</td>
                {tableData.map((section, index) => (
                  <td key={`${section.section}-Q${row}`} className="border-2 border-gray-300 p-2" style={{ backgroundColor: `${section.color}33` }}>
                    <div className="font-semibold text-gray-600">{section[`Q${row}`]}</div>
                  </td>
                ))}
              </tr>
              <tr>
                <td className="hide-on-mobile border-2 border-gray-300 p-2 font-bold bg-gray-200"></td>
                {tableData.map((section, index) => (
                  <td key={`${section.section}-A${row}`} className="border-2 border-gray-300 p-2 bg-white">
                    <textarea
                      className="w-full p-1 border border-gray-300 rounded"
                      value={section[`A${row}`]}
                      onChange={(e) => handleInputChange(index, `A${row}`, e.target.value)}
                      rows="3"
                    />
                  </td>
                ))}
              </tr>
            </React.Fragment>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default ColorfulEditableStrategyTable;